@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.list-li {
	list-style: disc;
	padding-left: 15px;
}

.hide-news-section {

	#lastviewed-units,
	.newsletter-post-bl {
		display: none;
	}
}

.owl-carousel {

	&.similar1,
	&.lastviewed-units {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.modal-dialog {
	&.favoriten {
		max-width: 400px;
	}

	.intro {
		padding-bottom: 15px;
	}

	.btn-line {
		margin-bottom: 15px;

		.btn {
			position: relative;

			.fa-whatsapp:before {
				content: "";
				background-image: url(RESOURCE/img/icon-whatsapp.svg);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 18px;
				width: 18px;
				display: inline-block;
				position: absolute;
				right: 15px;
				top: 10px;
			}

			.copylink {

				&.active {
					&:before {
						content: "\f00c";
						font-family: "Font Awesome 6 Pro";
						-webkit-font-smoothing: antialiased;
						font-weight: 600;
						display: inline-block;
						position: absolute;
						left: 15px;
						top: 5px;
					}

					&:hover {
						color: #FFF;
					}
				}
			}

			.icon {
				position: absolute;
				right: 15px;
				top: 10px;
			}
		}
	}

}

.hide-text {
	display: none;

	&.show {
		display: block;
	}
}

.p-20 {
	@media(max-width:767px) {
		padding: 0 20px;
	}
}

.p-10 {
	@media(max-width:767px) {
		padding: 0 10px;
	}
}

.btn-toggle {
	/*background-color: var(--btn-primary-bg-color);
	color: var(--btn-primary-font-color);
	border-color: var(--btn-primary-border-color);*/

	&:after {
		content: "mehr lesen";
	}

	&.active {
		&:after {
			content: "weniger anzeigen";
		}
	}
}


.icon-balcony {
	&:before {
		background-image: url(RESOURCE/img/icon-balcony.webp);
		content: "";
		width: 19px;
		height: 19px;
		display: inline-block;
		background-size: contain;
		/* background-position: center 2px; */
		background-repeat: no-repeat;
	}
}

.icon-terrace {
	&:before {
		background-image: url(RESOURCE/img/icon-terrace.svg);
		content: "";
		width: 19px;
		height: 19px;
		display: inline-block;
		background-size: contain;
		/* background-position: center 2px; */
		background-repeat: no-repeat;
	}
}


.icon-sauna {
	&:before {
		background-image: url(RESOURCE/img/icon-sauna.svg);
		content: "";
		width: 19px;
		height: 19px;
		display: inline-block;
		background-size: contain;
		/* background-position: center 2px; */
		background-repeat: no-repeat;
	}
}

.icon-wave {
	&:before {
		background-image: url(RESOURCE/img/icon-wave.svg);
		content: "";
		width: 19px;
		height: 19px;
		display: inline-block;
		background-size: contain;
		/* background-position: center 2px; */
		background-repeat: no-repeat;
		background-position: center bottom;
	}
}

.list-ol {
	padding-left: 15px;
}

b,
strong {
	font-weight: 600;
}

.checkbox input[type=checkbox]:focus+label:before {
	outline: 0 !important;
}

.doc-box {
	.popup-content {
		p {
			color: var(--h4-font-color) !important;
		}
	}
}

.info-popup-bl {
	.dlg {
		&.show {
			.box {
				max-width: 570px !important;
				height: 340px !important;

				@media (max-width:767px) {
					height: 440px !important;
				}


				.header[data-v-33d7ae1a] {
					padding: 0px 15px;
				}

				.info-popup-content {
					p {
						margin: 0;
					}
				}

				.body[data-v-d4acbada] {
					overflow: hidden !important;
				}

				.header-close {
					z-index: 5;
				}

				.header {
					border: none !important;
				}
			}
		}
	}
}

.unit-list-section1,
.unit-list-section {
	overflow: hidden;

	&.index-unit-list-section {
		overflow: visible;
	}

	.properties-row {
		min-height: 83px !important;
	}

	.owl-carousel {

		.owl-stage-outer {
			overflow: visible;
		}
	}
}

.image-cover {
	&.cover-top {
		object-position: top;
	}


	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.size100 {
	@mixin size100;
}

.pad-30 {
	@media(max-width:767px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.hidden {
	display: none;
}

.not-arrow {
	.dropdown-toggle {
		cursor: pointer;

		&:after {
			display: none !important;
		}
	}
}

.title-stars {
	.fa-star-o {
		display: none;
	}

	.fa-star {
		width: 18px;
		height: 18px;
		background-size: cover;
		background-image: url(RESOURCE/img/icon_star.webp);

		&:before {
			display: none;
		}
	}
}

.hide-tab {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-tab {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fa {
	&.fa-buildings {
		background-image: url(RESOURCE/img/icon-building.svg);
		width: 13px;
		opacity: .3 !important;
		height: 15px;
	}
}

.fa-paw {
	position: relative;

	&.cross {
		&:after {
			content: "";
			width: 1px;
			height: 22px;
			position: absolute;
			transform: rotate(-45deg);
			background-color: var(--color-black);
			left: 13px;
			top: -3px;
		}
	}
}

.fa-paw {
	&.cross {}
}

.fa-icon {
	background-repeat: no-repeat;
	display: inline-block;
	background-position: center;
	width: 18px;
	height: 18px;
	margin-left: 4px;

	&.fa-sauna {
		background-image: url(RESOURCE/img/icon-sharp.svg);
	}

	&.fa-fire {
		background-image: url(RESOURCE/img/icon-fire.svg);
	}

	&.fa-washer {
		background-image: url(RESOURCE/img/icon-washing.svg);
	}

	&.fa-dryer {
		background-image: url(RESOURCE/img/icon-dryer.svg);
	}
}

.icon-youtube {
	background-image: url(RESOURCE/img/icon-youtube.svg);
	width: 30px;
	height: 21px;
	background-size: cover;
	display: inline-block;
	background-repeat: no-repeat;
}

.blogcontent {
	margin-bottom: 30px;
	/*border-radius: var(--border-radius);
	box-shadow: 0 8px 8px 0px rgb(0 0 0 / 12%);
	padding: 15px;
	border: 1px solid var(--color-border);*/
	min-height: 602px;
	position: relative;

	h2 {
		margin-bottom: 20px;
		margin-top: 30px;
	}

	h3 {
		margin-bottom: 20px;
		margin-top: 30px;
	}

	.lead {
		font-weight: 400;
		margin-bottom: 30px;
		font-size: 1.1rem;
	}

	.content-col {
		/*background-color: #dad5f6;*/
		padding: 15px;
		border-radius: 0 0 5px 5px;


		.h6 {
			font-weight: 700;
			color: var(--color-secondary);
			font-size: 18px;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;

		}

		p:last-child {
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 100%;
		}

		.h4 {
			@media(min-width:993px) and (max-width:1200px) {
				min-height: 74px;
			}

			&.lead-text {
				min-height: 50px;
			}
		}

		&h4,
		span {
			font-weight: bold;
			color: var(--color-secondary);
		}

		.btn {
			width: 180px;
		}
	}

	.content-teaser-col {
		/*background-color: #dad5f6;*/
		padding: 15px;
		border-radius: 0 0 5px 5px;


		.h6 {
			font-weight: 700;
			color: var(--color-secondary);
			font-size: 18px;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;

		}

		p:last-child {
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 100%;
		}

		.h4 {
			@media(min-width:993px) and (max-width:1200px) {
				min-height: 74px;
			}

			&.lead-text {
				min-height: 50px;
			}
		}

		&h4,
		span {
			font-weight: bold;
			color: var(--color-secondary);
		}

		.btn {
			width: 180px;
		}
	}

	.image-col {
		height: 250px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 5px 5px 0 0;
		}
	}

}

.podcast-bl {
	margin-bottom: 25px;
	padding: 20px 20px 1px;
	background-color: #f07f09;
	border-radius: 10px;
	color: #fff;

	a {
		font-weight: bold;
		text-decoration: none;
		color: #fff;

		&:hover {
			font-weight: bold;
			text-decoration: none;
			color: #fff
		}

	}
}

.icon-envelope {
	background-image: url(RESOURCE/img/icon-envelope.svg);
	width: 16px;
	height: 17px;
	background-size: contain;
	background-position: center;
	position: relative;
	top: 3px;
}

.img-responsive {
	max-width: 100%;
}

.asd__wrapper {
	margin: 0 auto;
}

html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);

}

body.modal-open {
	overflow: hidden;
}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	position: relative;
	text-align: center;
	padding-bottom: 54px;

	span {
		font-size: 14px;
		display: block;
		font-weight: normal;
	}

	&:before {
		content: "";
		position: absolute;
		bottom: 20px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 15%;
		height: 1px;
		background-color: var(--color-primary);

		@media(max-width: 992px) {
			width: 20%;
		}

		@media(max-width: 768px) {
			width: 25%;
		}

		@media(max-width: 576px) {
			width: 30%;
		}
	}
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
	text-decoration: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary) !important;
}

.bg-primary-light {
	background-color: var(--color-primary-light) !important;
}

.bg-secondary {
	background-color: var(--color-secondary) !important;
}

.bg-secondary-light {
	background-color: var(--color-secondary-light) !important;
}

.bg-white {
	background-color: var(--color-white) !important;
}

.bg-grey-light {
	background-color: var(--color-grey-light) !important;
}

.bg-grey-normal {
	background-color: var(--color-grey-normal) !important;
}

.bg-grey-dark {
	background-color: var(--color-grey-dark) !important;
}

.bg-black {
	background-color: var(--color-black) !important;
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.datepicker-trigger {
	.asd__day {
		line-height: 30px;
		height: 35px;
		padding: 0;
		overflow: hidden;
		border: none !important;

	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		/*border: 1px double var(--cal-selected-border) !important;*/
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--unit-cal-selected) !important;
		opacity: 1 !important;

		/*border: 1px double var(--cal-selected-border) !important;*/
		button {
			color: var(--color-black);
		}
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		/*border: 1px double var(--cal-selected-border) !important;*/
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;

		/*border: 1px double var(--cal-selected-border) !important;*/
		button {
			color: var(--color-black);
		}
	}

	.asd__day--enabled {
		background-color: transparent !important;

		font-weight: 400;

		button {
			font-weight: 600;
			color: #000;
		}

		&.asd__day--disabled:not(.asd__selected-date-one) {

			text-decoration: underline;
			font-weight: 400;

			button {
				pointer-events: none;
				background-color: transparent;
				color: var(--cal-text-color);
				text-decoration: line-through;
				opacity: 0.5;
			}

			&.asd__day--selected {
				button {
					background-color: var(--color-primary) !important;
					border-radius: 50%;
				}
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				/*border: 1px double var(--cal-selected-border) !important;*/
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			button {
				background: var(--color-primary) !important;
				color: var(--cal-text-color-hover) !important;
				border-radius: 50%;
			}

			opacity: 1 !important;

		}
	}

	.asd__day--selected {
		background: transparent !important;
		position: relative;
		opacity: 1 !important;
		/*border: 1px double var(--cal-selected-border) !important;*/

		&.asd__selected-date-one {

			button {
				border-radius: 50%;
				position: relative;
				background: var(--color-primary) !important;
				color: #fff;
			}

			&:before {
				content: "";
				position: absolute;
				width: 16px;
				height: 100%;
				display: block;
				background-color: var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			button {
				border-radius: 50%;
				position: relative;
				background: var(--color-primary) !important;
				color: var(--cal-text-color-selected) !important;
				color: #fff;
			}

			&:before {
				content: "";
				position: absolute;
				width: 16px;
				height: 100%;
				display: block;
				background-color: var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

.impressum-text-link {
	color: var(--color-primary);
}

.list-bullet {
	padding-left: 18px;

	li {
		list-style: disc;
	}
}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	&.btn-secondary-bdr {
		border-color: var(--btn-secondary-border-color);
		color: var(--color-secondary);

		@media (max-width:575px) {
			width: 100%;
		}

		&:hover {
			background-color: var(--btn-secondary-bg-color);
			color: var(--btn-secondary-font-color);
		}
	}

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}

		&:focus {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}

		&:focus {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-black {
		background-color: var(--color-black);
		color: var(--color-white);
		border-color: var(--color-white);

		&:hover {
			background-color: var(--color-white);
			border-color: var(--color-black);
			color: var(--color-black);
		}

		&:focus {
			background-color: var(--color-white);
			border-color: var(--color-black);
			color: var(--color-black);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}

		&:focus {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}

		&:focus {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}

		&:focus {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}

		&:focus {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;
	flex-wrap: nowrap;

	li {

		display: flex;

		@media(max-width:767px) {
			font-size: 14px;
		}

		&.active {
			span {
				color: #6c757d;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				max-width: 210px;
			}
		}
	}

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: -54px;
		right: 0px;
		width: 100%;

		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-primary) !important;
			position: absolute;
			left: 0;
			text-align: center;


			span {
				font-family: "Font Awesome 6 Pro";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;
				padding-left: 3px;

				&:before {
					color: var(--color-white);
					font-family: "Font Awesome 6 Pro";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: inherit;


				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				left: 0;

				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel {
	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 15px 0 0 0;

		.owl-dot {
			width: 15px;
			height: 15px;
			background: #b5b5b5;
			margin: 0 5px;
			border-radius: 100px;

			&.active {
				background: var(--color-primary);
			}
		}
	}
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/banner1.webp);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 50vh;
	display: none;
	position: relative;

	@media(max-width:992px) {
		max-height: 320px;
	}

	@media(max-width:768px) {
		max-height: 290px;
	}

	@media(max-width:576px) {
		max-height: 240px;
	}


	.page-title {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;

		@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 0 10px;

			@media (max-width:992px) {
				font-size: 24px;
			}

			span {
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
			}
		}


	}
}

.privacy-link {
	text-decoration: underline;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.box.full-xxs.datepickerpopup.show {
	z-index: 1000 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (min-width: 768px) {
		justify-content: space-evenly;
	}

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 20px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}




.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:767px) {
		display: none;
	}
}

.show-tab {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.show-mob {
	display: none;

	@media (max-width:767px) {
		display: block;
	}
}

/*

.fal {
	font-family: 'Font Awesome 5 Free' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}*/



.popup-header {
	font-size: var(--popup-header-font-size);
	color: var(--popup-header-color);
	font-family: var(--popup-header-font-family);
}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.modal-custom {
	display: none;
	position: fixed;
	z-index: 99;
	padding-top: 20px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

}

/* Modal Content */
.modal-custom-dialog {
	background-color: #fff;
	margin: auto;
	padding: 0px 10px;
	border: 1px solid #888;
	width: 90%;
	max-width: 1150px;
	border-radius: var(--border-radius);


	.close {
		color: #777;
		position: absolute;
		width: 100%;
		font-size: 26px;
		height: 30px;
		width: 30px;
		right: -5px;
		text-align: center;
		line-height: 30px;
		top: 4px;
		font-weight: 400;
		font-family: arial, helvetica, sans-serif;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}

}

.modal-custom-header {
	height: 35px;
	text-align: right;
	position: relative;
}

.modal-custom-footer {
	height: 35px;
	text-align: center;
	padding-right: 20px;
	padding-top: 5px;

	@media (min-width: 992px) {
		text-align: right;
	}
}

.modal-custom-content {
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 20px;
}

/* The Close Button */

.hide-576 {
	@media (max-width: 576px) {
		display: none;
	}
}

.hide-767 {
	@media (max-width: 767px) {
		display: none;
	}
}

.show-only-767 {
	display: none;

	@media (max-width: 767px) {
		display: block;
	}
}

.hide-991 {
	@media (max-width: 991px) {
		display: none;
	}
}

.show-only-991 {
	display: none;

	@media (max-width: 991px) {
		display: block;
	}
}

.relative {
	position: relative;
}

.visible-print {
	display: none !important;
}

.openstreetwarning {
	position: relative;
	background-color: #000;
	aspect-ratio: 16/9;
	padding: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;

	@media(max-width:500px) {
		padding: 5px;
		font-size: 13px;
		flex-direction: inherit;
	}

	.text {
		text-align: center;
	}

	.button-line {
		text-align: center;
		margin: 10px 0px;

		@media(max-width:500px) {
			margin: 0px 0px;
		}
	}

	a {
		color: var(--color-white);
	}
}

.subtitle {
	padding-bottom: 17px;
	font-size: 20px;
	margin-top: -17px;
	color: var(--color-primary);
	font-weight: 400;
	text-indent: 5px;
	position: relative;
}

.tooltip-custom {
	position: relative;
}

.tooltip-custom .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: var(--border-radius);
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip-custom .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.boldtext {
	font-weight: 600;
}

#cboxPrevious,
#cboxNext {
	background-image: url(RESOURCE/img/controls3.png) !important;

}

#cboxClose {
	background-image: url(RESOURCE/img/controls3.png) !important;
	top: 4px !important;

}

#cboxPrevious {
	left: 4px !important;
}

#cboxNext {
	right: 4px !important;
}

#cboxOverlay {
	background: #000;
}

#cboxContent,
.cboxIframe {
	margin: 0 auto;
}

.voucher-input {
	cursor: text !important;
}

.ccm--consent-switch {
	display: none;
}

@media (min-width:1240px) {

	.container,
	.container-xl {
		max-width: 1240px;
	}
}

@media (max-width: 767px) {
	.hide-xs {
		display: none;
	}

	.container,
	.container-xl {
		max-width: 720px;
	}
}

@media print {
	.visible-print {
		display: block !important;
	}

}